<template>
    <div
        v-for="( list, index ) in getLists"
        :key="`media-list-${ index }`"
        :class="`media-${ list.columns === '1' ? 'full' : 'half' }`"
        class="media-list">
        <component
            :is="`c_${ list.type }`"
            :obj="list" />
    </div>    
</template>

<script>
    import MediaImage from '@/components/MediaImage'
    import MediaVideo from '@/components/MediaVideo'

    export default {
        name: 'medialist',

        components: {
            c_gallery: MediaImage,
            c_videos: MediaVideo
        },

        props: {
            obj: Object
        },

        computed: {
            getLists() {
                let lists = []
                let videos = null
                let square = null
                let type = null
                for ( let i = 0; i < this.obj.length; i++ ) {
                    let media = this.obj[i]
                    if ( videos && ( media.type !== type || ( media.type === 'video' && media.square !== square ) ) ) {
                        lists.push( {
                            type: 'videos',
                            columns: square ? '2' : '1',
                            videos
                        } )
                        videos = null
                        square = null
                    }
                    if ( media.type === 'gallery' ) {
                        lists.push( media )
                        type = media.type
                    } else {
                        if ( !videos ) {
                            videos = []
                            square = media.square
                        }
                        videos.push( media )
                        type = media.type
                    }
                }
                if ( videos ) {
                    lists.push( {
                        type: 'videos',
                        columns: square ? '2' : '1',
                        videos
                    } )
                }
                // console.log( lists )
                return lists
            }
        }
    }
</script>
