<template>
    <div class="heading">
        <h1
            v-if="obj.id !== S.data.menu.intro" >
            {{ obj.title }}
        </h1>
        <ProjectInfo
            v-if="info"
            :obj="obj" />
    </div>
</template>

<script>
    import ProjectInfo from '@/components/ProjectInfo'
    import S from '@/scripts/site'

    export default {
        name: 'heading',

        data() {
            return {
                S
            }
        },

        props: {
            obj: Object,
            info: {
                type: Boolean,
                default: false
            }
        },

        components: {
            ProjectInfo
        }
    }
</script>
