<template>
    <div
        v-for="( img, idx ) in obj.images"
        :key="`media-item-${ idx }`"
        class="media-item media-image animate" >
        <div
            :style="img._style"
            class="media-container">
            <img
                :data-src="img._url"
                alt="" >
        </div>
        <MediaCaption
            v-if="img.caption && img.caption !== ''"
            :caption="img.caption" />
    </div>
</template>

<script>
    import MediaCaption from '@/components/MediaCaption'
    // import T from '@/scripts/tools'

    export default {
        name: 'mediaimage',

        components: {
            MediaCaption
        },

        props: {
            obj: Object
        },

        beforeCreate() {
            this.obj.images.forEach( img => {
                img._url = img.thumb
                // let ratio = T.getAspectRatio( img.thumb, true )
                // if ( !ratio ) {
                //     ratio    = 100
                //     img._url = img.square
                // }
                let style = {
                    paddingBottom: `${ img.ratio }%`
                }
                if ( img.color && img.color !== '' ) {
                    style.backgroundColor = img.color
                }
                img._style = style
            } )
        }
    }
</script>
