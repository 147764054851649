import { createRouter, createWebHistory } from 'vue-router'
import S from '@/scripts/site'
import List from '@/views/List.vue'
import Page from '@/views/Page.vue'
import Project from '@/views/Project.vue'
import NotFound from '@/views/404.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: {
            template: '<component :is="getComponent" :slug="getSlug" />',
            computed: {
                getComponent() {
                    let menu = S.getMenu()
                    let home = S.getPageById( menu.intro )
                    if ( !home ) {
                        return NotFound
                    } else if ( home.type === 'list' ) {
                        return List
                    }
                    return Page
                },
                getSlug() {
                    let menu = S.getMenu()
                    let home = S.getPageById( menu.intro )
                    if ( !home ) {
                        return 'notfound'
                    }
                    return home.slug
                }
            }
        }
    },
    {
        path: '/project/:slug',
        name: 'project',
        component: {
            template: '<component :is="getComponent" :slug="$route.params.slug" />',
            computed: {
                getComponent() {
                    let project = S.getProjectBySlug( this.$route.params.slug )
                    if ( !project ) {
                        return NotFound
                    }
                    return Project
                }
            }
        }
    },
    {
        path: '/:slug',
        name: 'page',
        component: {
            template: '<component :is="getComponent" :slug="$route.params.slug" />',
            computed: {
                getComponent() {
                    let page = S.getPageBySlug( this.$route.params.slug )
                    if ( !page ) {
                        return NotFound
                    } else if ( page.type === 'list' ) {
                        return List
                    }
                    return Page
                }
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: {
            template: '<component :is="getComponent" slug="notfound" />',
            computed: {
                getComponent() {
                    return NotFound
                }
            }
        }
    }
]

const router = createRouter( {
    history: createWebHistory( process.env.BASE_URL ),
    routes
} )

export default router
