<template>
    <div class="project-list">
        <ProjectPreview
            v-for="( item, index ) in obj.projects"
            :key="`project-preview-${ index }`"
            :txt="obj.excerpts"
            :obj="item" />
    </div>
</template>

<script>
    import ProjectPreview from '@/components/ProjectPreview'

    export default {
        name: 'projectlist',

        components: {
            ProjectPreview
        },

        props: {
            obj: Object
        }
    }
</script>
