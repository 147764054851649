<template>
    <div class="container list" data-type="list" :data-slug="slug">
        <Heading
            :obj="P" />
        <Content
            v-if="P.content && P.content !== ''"
            :obj="P" />
        <ProjectList :obj="P" />
    </div>
</template>

<script>
    import Heading from '@/components/Heading'
    import Content from '@/components/Content'
    import ProjectList from '@/components/ProjectList'
    import S from '@/scripts/site'

    export default {
        name: 'list',

        components: {
            Heading,
            Content,
            ProjectList
        },

        props: {
            slug: String
        },

        computed: {
            P() {
                return S.getPageBySlug( this.slug )
            }
        }
    }
</script>
