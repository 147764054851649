<template>
    <div class="project-info">
        <strong>{{ ( `00${ obj.num }` ).slice( - 3 ) }}</strong>
        | {{ obj.year }}
        {{ getCategories }}
    </div>
</template>

<script>
    import S from '@/scripts/site'

    export default {
        name: 'projectinfo',

        props: {
            obj: Object
        },

        computed: {
            getCategories() {
                let cats = ''
                this.obj.categories.forEach( id => {
                    let cat = S.getCategoryById( id )
                    cats += ` | ${ cat.title }`
                } )
                return cats
            }
        }
    }
</script>
