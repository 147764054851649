<template>
    <div class="page" data-type="page" :data-slug="slug">
        <div class="container">
            <Heading :obj="P" />
            <Content
                v-if="P.content && P.content !== ''"
                :obj="P" />
            <MediaList
                v-if="P.media && P.media.length"
                :obj="P.media" />
        </div>
        <div
            v-if="P.excerpts && P.excerpt && P.excerpt !== ''"
            class="container project-more" >
            <div
                v-if="P.excerptTitle && P.excerptTitle !== ''"
                class="heading">
                <h2>{{ P.excerptTitle }}</h2>
            </div>
            <div
                class="content animate"
                v-html="P.excerpt">
            </div>
        </div>
    </div>
</template>

<script>
    import Heading from '@/components/Heading'
    import Content from '@/components/Content'
    import MediaList from '@/components/MediaList'
    import S from '@/scripts/site'

    export default {
        name: 'page',

        components: {
            Heading,
            Content,
            MediaList
        },

        props: {
            slug: String
        },

        computed: {
            P() {
                return S.getPageBySlug( this.slug )
            }
        }
    }
</script>
