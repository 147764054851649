<template>
    <div class="project" data-type="project" :data-slug="slug">
        <div class="container">
            <Heading
                :obj="P"
                :info="true" />
            <!-- Show only media if there's no content -->
            <MediaList
                v-if="!C && P.media && P.media.length"
                :obj="P.media" />
            <!-- Otherwise split media into main and more -->
            <MediaList
                v-if="C && Media.main"
                :obj="Media.main" />
            <Content
                v-if="C"
                :obj="P" />
            <MediaList
                v-if="C && Media.more"
                :obj="Media.more" />
        </div>
        <div class="container project-more">
            <div class="heading">
                <h2>More Projects</h2>
            </div>
            <ProjectList :obj="More" />
        </div>
    </div>
</template>

<script>
    import Heading from '@/components/Heading'
    import Content from '@/components/Content'
    import MediaList from '@/components/MediaList'
    import ProjectList from '@/components/ProjectList'
    import S from '@/scripts/site'

    export default {
        name: 'project',

        components: {
            Heading,
            Content,
            MediaList,
            ProjectList
        },

        props: {
            slug: String
        },

        computed: {
            P() {
                return S.getProjectBySlug( this.slug )
            },

            C() {
                return this.P.content && this.P.content !== ''
            },

            More() {
                let p = S.data.projects
                let i = p.findIndex( el => el.slug === this.slug )
                let l = i - 1 < 0 ? p.length - 1 : i - 1
                let n = i + 1 >= p.length ? 0 : i + 1
                return {
                    projects: [
                        {
                            id: `${ p[l].id }`,
                            variation: '0'
                        },
                        {
                            id: `${ p[n].id }`,
                            variation: '0'
                        }
                    ]
                }
            },

            Media() {
                let main = null
                let more = null
                let media = this.P.media
                if ( media && media.length ) {
                    main = []
                    media.forEach( item => {
                        if ( more ) {
                            more.push( item )
                        } else {
                            if ( item.type === 'video' ) {
                                main.push( item )
                                if ( !item.square || main.length >= 2 ) {
                                    more = []
                                }
                            } else {
                                let len = Math.min( 2, parseInt( item.columns ) )
                                let gal = { ...item }
                                gal.images = item.images.slice( 0, len )
                                main.push( gal )
                                more = []
                                if ( item.images.length > len ) {
                                    gal = { ...item }
                                    gal.images = item.images.slice( len )
                                    more.push( gal )
                                }
                            }
                        }
                    } )
                }
                return {
                    main,
                    more
                }
            }
        }
    }
</script>
