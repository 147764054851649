import W from '@wamms/tinylib'

const easing   = 4
const duration = 750
const stagger  = 200

export default {

    menu: false,

    // animate menu
    toggleMenu( done ) {
        const tl = new W.tl()

        // close menu
        if ( this.menu ) {
            // tl.add( {
            //     s: '#menu-contact',
            //     d: duration * 0.75,
            //     e: `i3`,
            //     p: {
            //         x: [0, -101]
            //     }
            // } )
            tl.add( {
                s: '#menu',
                d: duration * 0.75,
                e: `i3`,
                p: {
                    x: [0, 101]
                },
                c: () => {
                    this.menu = !this.menu
                    done()
                }
            } )

            W.sel( '#burger' )[0].classList.remove( 'open' )
        }
        // open menu
        else {
            const con     = W.sel( '#menu-contact' )
            const conRect = con[0].getBoundingClientRect()
            const nav     = W.sel( '#menu-nav' )
            const navRect = nav[0].getBoundingClientRect()
            // if ( conRect.top !== navRect.top ) {
                if ( conRect.width > navRect.width ) {
                    nav[0].style.width = `${ conRect.width }px`
                } else if ( navRect.width > conRect.width ) {
                    con[0].style.width = `${ navRect.width }px`
                }
            // } else {
            //     nav[0].style.width = 'unset'
            //     nav[0].style.width = 'unset'
            // }

            // tl.add( {
            //     s: '#menu-contact',
            //     d: duration * 0.75,
            //     e: `o3`,
            //     p: {
            //         x: [-101, 0]
            //     }
            // } )
            tl.add( {
                s: '#menu',
                d: duration * 0.75,
                e: `o3`,
                p: {
                    x: [101, 0]
                },
                c: () => {
                    this.menu = !this.menu
                    done()
                }
            } )

            W.sel( '#burger' )[0].classList.add( 'open' )
        }

        tl.play()
    },

    // zoom in project preview
    zoom( prj, offx, offy, from, to, done ) {
        const tl = new W.tl()
        const ol = W.sel( '#overlay' )
        ol[0].style.transform = 'scaleX( 0 )'
        ol[0].style.display   = 'block'

        tl.add( {
            s: [ prj ],
            d: duration,
            e: `io3`,
            p: {
                x: [0, offx, 'px'],
                y: [0, offy, 'px'],
                s: [from, to]
            }
        } )

        tl.add( {
            s: ol,
            d: duration * 0.75,
            w: duration * 1.25,
            e: `i3`,
            p: {
                sx: [0, 1]
            },
            c: () => {
                done()
            }
        } )

        tl.play()
    },

    // animate items on entering
    enter( done ) {
        const tl = new W.tl()

        // animate project info
        tl.add( {
            s: '.heading .project-info',
            d: duration,
            e: `o${ easing }`,
            p: {
                o: [0, 1],
                x: [20, 0, 'vh']
            }
        } )

        // animate heading
        tl.add( {
            s: '.heading h1',
            d: duration,
            e: `o${ easing }`,
            p: {
                o: [0, 1],
                x: [-20, 0, 'vh']
            }
        } )

        // find items to animate
        let anims  = []
        let itmes  = W.sel( '.animate' )
        let height = window.innerHeight
        itmes.forEach( el => {
            let rect = el.getBoundingClientRect()
            if ( rect.top >= height ) {
                el.style.opacity = 1
            } else {
                anims.push( el )
            }
        } )

        // animate items
        tl.stagger( stagger, {
            s: anims,
            d: duration,
            e: `o${ easing }`,
            p: {
                o: [0, 1],
                y: [20, 0, 'vh']
            },
            c: () => {
                done()
            }
        } )

        // play animation
        tl.play()
    },

    // animate items on leaving
    leave( done ) {
        const tl = new W.tl()

        // find items to animate
        let anims  = []
        let itmes  = W.sel( '.animate, .heading' )
        let height = window.innerHeight
        itmes.forEach( el => {
            let rect = el.getBoundingClientRect()
            if ( rect.top < height && rect.top + rect.height >= 0 ) {
                anims.push( el )
            }
        } )

        // animate items
        tl.stagger( stagger, {
            s: anims,
            d: duration,
            e: `i${ easing }`,
            p: {
                o: [1, -0.1],
                y: [0, -20, 'vh']
            },
            c: () => {
                done()
            }
        } )

        // play animation
        tl.play()
    }
}