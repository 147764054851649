<template>
    <div
        v-for="( video, idx ) in obj.videos"
        :key="`media-item-${ idx }`"
        class="media-item media-video animate" >
        <div
            :style="getStyle( video )"
            class="media-container" >
            <iframe
                :src="`https://player.vimeo.com/video/${ video.vimeo }?color=383838&title=0&byline=0&portrait=0`"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'mediavideo',

        props: {
            obj: Object
        },

        methods: {
            getStyle( video ) {
                let style = {
                    paddingBottom: video.square ? '100%' : ( video.ratio && video.ratio !== '' ? `${ video.ratio }%` : '56.25%' )
                }
                if ( video.color && video.color !== '' ) {
                    style.backgroundColor = video.color
                }
                return style
            }
        }
    }
</script>
