<template>
    <div class="container notfound" data-type="notfound" :data-slug="slug">
        <Heading :obj="P" />
        <Content :obj="P" />
    </div>
</template>

<script>
    import Heading from '@/components/Heading'
    import Content from '@/components/Content'

    export default {
        name: 'notfound',

        components: {
            Heading,
            Content
        },

        data() {
            return {
                P: {
                    title: '404',
                    content: '<p>These aren\'t the droids you\'re looking for.</p>'
                }
            }
        }
    }
</script>
