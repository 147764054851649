<template>
    <router-link
        :to="`/project/${ getProject.slug }`"
        v-slot="{ href, navigate }"
        custom>
        <div class="project-preview animate"> 
            <a
                
                :href="href"
                @click="navigate">
                <h3>{{ getProject.title }}</h3>
                <div
                    :style="getStyle( getImage )"
                    class="media-container">
                    <div class="media-mix">
                        <img
                            :data-src="getImage.thumb"
                            alt="" >
                        <video
                            disablePictureInPicture
                            playsinline
                            muted
                            loop
                            preload="none">
                            <source
                                :v-if="T.hasMouse() === true"
                                :src="getVariation.clip"
                                type="video/mp4" />
                        </video>
                    </div>
                </div>
                <ProjectInfo :obj="getProject" />
            </a>
            <div
                v-if="txt && getProject.excerpt && getProject.excerpt !== ''"
                v-html="getProject.excerpt"
                class="project-excerpt" />
        </div>
    </router-link>
</template>

<script>
    import ProjectInfo from '@/components/ProjectInfo'
    import S from '@/scripts/site'
    import T from '@/scripts/tools'

    export default {
        name: 'projectpreview',

        data() {
            return {
                T
            }
        },

        props: {
            obj: Object,
            txt: Boolean
        },

        components: {
            ProjectInfo
        },

        computed: {
            getProject() {
                return S.getProjectById( this.obj.id )
            },

            getVariation() {
                let project = this.getProject
                return project.variations[Math.min( project.variations.length - 1, parseInt( this.obj.variation ) )] 
            },

            getImage() {
                return this.getVariation.image
            }
        },

        methods: {
            getStyle( img ) {
                let style = {
                    // paddingBottom: `${ T.getAspectRatio( img.thumb ) }%`
                    paddingBottom: '56.25%' // 16:9
                }
                if ( img.color && img.color !== '' ) {
                    style.backgroundColor = img.color
                }
                return style
            }
        }
    }
</script>
