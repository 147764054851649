import W from "@wamms/tinylib"

export default {
    // cms base
    cms: '/backend/static.json',
    // cms: 'https://krishofmann.co.uk/backend/static.json',
    // cms: 'http://krishofmann.co.uk/backend/wp-json/wamms/data',
    // cms: '/cms/data.json',
    // site data
    data: null,

    // fetch site data
    init( callback ) {
        fetch( `${ this.cms }` )
            .then( response => response.json() )
            .then( data => {
                // store data
                this.data = data
                // finally continue
                callback()
            } )
            .catch( () => {
                // console.error( 'Error fetching site\n', error )
                W.sel( '#issue-old p' )[0].innerHTML = 'Error fetching content, try again later!'
                W.body.classList.add( 'old' )
             } )
    },

    getMenu() {
        return this.data.menu
    },

    getCategoryById( id ) {
        return this.data.categories.find( el => el.id === parseInt( id ) )
    },

    getPageById( id ) {
        return this.data.pages.find( el => el.id === parseInt( id ) )
    },

    getPageBySlug( slug ) {
        return this.data.pages.find( el => el.slug === slug )
    },

    getProjectById( id ) {
        return this.data.projects.find( el => el.id === parseInt( id ) )
    },

    getProjectBySlug( slug ) {
        return this.data.projects.find( el => el.slug === slug )
    },

    getNextProject( id ) {
        let projects = this.data.projects
        for ( let i = 0; i < projects.length; i++ ) {
            if ( projects.id === parseInt( id ) ) {
                return i + 1 >= projects.length ? projects[0] : projects[i + 1]
            }
        }
        return null
    },

    // update title
    setTitle( name = null ) {
        document.title = `${ name ? name + ' | ' : '' }${ this.data.menu.title }`;
    }
}