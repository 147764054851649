<template>
    <!-- v-bind="$props" so the dynamic component has access to the parent component props -->
    <component
        v-bind="$props"
        :is="getCaption" />

    <!-- without dynamic runtime compilation -->
    <!-- <div class="media-caption">
        <span
            v-for="( item, index ) in getSpans"
            :key="`media-span-${ index }`" >
            <i v-if="item.type === 'span'">{{ item.data }}</i>
            <router-link
                v-if="item.type === 'link'"
                :to="`/project/${ item.slug }`" >
                {{ item.data }}
            </router-link>
        </span>
    </div> -->
</template>

<script>
    import S from '@/scripts/site'

    export default {
        name: 'mediacaption',

        props: {
            caption: String
        },

        computed: {
            getCaption() {
                // dynamic component
                return {
                    template: `<div class="media-caption">${ this.parseCaption( this.caption ) }</div>`,
                    props: this.$options.props
                }
            },

            // getSpans() {
            //     const spans   = []
            //     let   caption = this.caption
            //     while ( caption != '' ) {
            //         // find next project slug
            //         let idx = caption.indexOf( '##' )
            //         // if no slug was found...
            //         if ( idx < 0 ) {
            //             // ...add rest of caption and exit
            //             spans.push( {
            //                 type: 'span',
            //                 data: caption
            //             } )
            //             break
            //         }
            //         // store caption part in front of slug
            //         spans.push( {
            //             type: 'span',
            //             data: caption.substring( 0, idx )
            //         } )
            //         // remove caption part in front of slug
            //         caption = caption.substring( idx + 2 )
            //         // find end of current slug
            //         idx = caption.indexOf( '##' )
            //         // if slug has no end...
            //         if ( idx < 0 ) {
            //             // ...add rest of caption and exit
            //             spans.push( {
            //                 type: 'span',
            //                 data: caption
            //             } )
            //             break
            //         }
            //         // extract project slug
            //         let slug = caption.substring( 0, idx )
            //         // remove slug from caption
            //         caption = caption.substring( idx + 2 )
            //         // try to find the project
            //         let prj = S.getProjectBySlug( slug )
            //         if ( prj ) {
            //             // ...add link and name to caption
            //             spans.push( {
            //                 type: 'link',
            //                 data: prj.title,
            //                 slug
            //             } )
            //         } else {
            //             // ...otherwise add slug to caption
            //             spans.push( {
            //                 type: 'span',
            //                 data: slug
            //             } )
            //         }
            //     }
            //     return spans
            // }
        },

        methods: {
            parseCaption( caption ) {
                // project slugs can be embedded by using
                // ##slug## and get converted to router links
                
                // processed caption
                let result = ''

                while ( caption != '' ) {
                    // find next project slug
                    let idx = caption.indexOf( '##' )
                    // if no slug was found...
                    if ( idx < 0 ) {
                        // ...add rest of caption and exit
                        result += caption
                        break
                    }
                    // store caption part in front of slug
                    result += caption.substring( 0, idx )
                    // remove caption part in front of slug
                    caption = caption.substring( idx + 2 )
                    // find end of current slug
                    idx = caption.indexOf( '##' )
                    // if slug has no end...
                    if ( idx < 0 ) {
                        // ...add rest of caption and exit
                        result += caption
                        break
                    }
                    // extract project slug
                    let slug = caption.substring( 0, idx )
                    // remove slug from caption
                    caption = caption.substring( idx + 2 )
                    // try to find the project
                    let prj = S.getProjectBySlug( slug )
                    // if the project exists...
                    if ( prj ) {
                        // ...add link and name to caption
                        result += `<router-link to="/project/${ slug }">${ prj.title }</router-link>`
                    } else {
                        // ...otherwise add slug to caption
                        result += slug
                    }
                }

                return result
            }
        }
    }
</script>
