<template>
    <nav>
        <div id="nav-logo">
            <router-link
                to="/"
                v-slot="{ isActive, href, navigate }"
                custom>
                <a
                    :href="href"
                    @click="navigate"
                    :class="isActive ? 'active' : 'inactive'" >
                    {{ S.data.menu.title }}
                </a>
            </router-link>
        </div>
        <div id="nav-items">
            <!-- <router-link
                v-for="( item, index ) in S.data.menu.pages"
                :to="`/${ S.getPageById( item.id ).slug }`"
                :key="`nav-item-${ index }`"
                v-slot="{ isActive, href, navigate }"
                custom>
                <a
                    :href="href"
                    @click="navigate"
                    :class="isActive ? 'active' : 'inactive'"
                    :data-txt="item.title" >
                    {{ item.title }}
                </a>
            </router-link> -->
            <button
                id="burger"
                aria-label="Menu"
                @click="toggleMenu" >
                <span />
                <span />
                <span />
            </button>
        </div>
    </nav>
    <main>
        <router-view v-slot="{ Component, route }">
            <transition
                mode="out-in"
                @enter="routerEnter"
                @leave="routerLeave"
                :css="false"
                appear>
                <component
                    :is="Component"
                    :key="route.path"
                />
            </transition>
        </router-view>
    </main>
    <div id="overlay" />
    <div id="menu">
        <div
            id="menu-contact"
            class="menu-container"
            v-html="getContact">
        </div>
        <div id="menu-nav" class="menu-container">
            <router-link
                v-for="( item, index ) in S.data.menu.pages"
                :to="`/${ parseInt( item.id ) === S.data.menu.intro ? '' : S.getPageById( item.id ).slug }`"
                :key="`nav-item-${ index }`"
                v-slot="{ isActive, href, navigate }"
                custom>
                <a
                    :href="href"
                    @click="navigate"
                    class="menu-heading"
                    :class="isActive ? 'active' : 'inactive'"
                    :data-txt="item.title" >
                    {{ item.title }}
                </a>
            </router-link>
        </div>
    </div>
</template>

<script>
    import W from '@wamms/tinylib'
    import S from '@/scripts/site'
    import T from '@/scripts/tools'
    import A from '@/scripts/animate'

    export default {
        data() {
            return {
                refreshing: false,      // are we currently refreshing
                registration: null,     // service worker registration
                updateExists: false,    // is an update available
                lastView: null,         // type of last view
                nextView: null,         // object for next view            
                S                       // cms
            }
        },

        created() {
            // base app created
            // console.log( 'created' )

            // reset scroll position on refresh
            W.scroll.reset()

            // listen to the service worker update event
            document.addEventListener(
                'swUpdated', this.showRefreshUI, {
                    once: true
                }
            )

            // wait for the new service worker to take over
            if ( navigator.serviceWorker ) {
                navigator.serviceWorker.addEventListener(
                    'controllerchange', () => {
                        if ( this.refreshing ) {
                            return
                        }

                        // refresh the current page
                        this.refreshing = true
                        location.reload()
                    }
                )
            }
        },

        computed: {
            getContact() {
                return `<div class="menu-heading">${ S.data.contact.title }</div>${ S.data.contact.content }`
            }
        },

        methods: {
            showRefreshUI( event ) {
                // store the service worker registration
                this.registration = event.detail
                // activate the update ui
                this.updateExists = true
            },

            refreshApp() {
                this.updateExists = false
                // early out if no service worker registration is stored
                if ( !this.registration || !this.registration.waiting ) {
                    return
                }
                // notify the new service worker to take over
                this.registration.waiting.postMessage( {
                    type: 'SKIP_WAITING'
                } )
            },

            dismissRefresh() {
                this.updateExists = false
            },

            getTypeTitle() {
                // find type and title for current route
                let title = '404'
                let type = 'notfound'
                if ( this.$route.name === 'project' ) {
                    let slug = this.$route.params.slug
                    let project = S.getProjectBySlug( slug )
                    if ( project ) {
                        type = 'project'
                        title = project.title
                    }
                } else if ( this.$route.name === 'page' ) {
                    let slug = this.$route.params.slug
                    let page = S.getPageBySlug( slug )
                    if ( page ) {
                        type = page.type
                        title = page.title
                    }
                } else if ( this.$route.name === 'home' ) {
                    let menu = S.getMenu()
                    let home = S.getPageById( menu.intro )
                    if ( home ) {
                        type = home.type
                        title = ''
                    }
                }

                return {
                    title,
                    type
                }
            },

            toggleMenu() {
                if ( window._popState.block ) {
                    return
                }

                // block back button
                window._popState.block = true

                // disable scrolling and links
                W.body.classList.add( 'fixed' )
                W.body.classList.add( 'block' )

                A.toggleMenu( () => {
                    // unblock back button
                    window._popState.block = false

                    // enable links
                    W.body.classList.remove( 'block' )
                    // enable scrolling if menu is closed
                    if ( !A.menu ) {
                        W.body.classList.remove( 'fixed' )
                    }
                } )
            },

            routerLeave( el, done ) {
                this.nextView = this.getTypeTitle()

                // the current route is beeing left
                // console.log( `leaving <${ el.dataset.type }> to <${ this.nextView.type }>` )

                // block back button
                window._popState.block = true
                // store current view
                this.lastView = el.dataset.type
                
                // disable scrolling and links
                W.body.classList.add( 'fixed' )
                W.body.classList.add( 'block' )

                // remove eventlisteners
                W.sel( '.project-preview a' ).forEach( el => {
                    el.removeEventListener( 'mouseenter', this.hoverBegin )
                    el.removeEventListener( 'mouseleave', this.hoverEnd )
                } )

                // if menu is open, show overlay and close menu
                if ( A.menu ) {
                    // show overlay behind menu
                    const ol = W.sel( '#overlay' )[0]
                    ol.style.transform = 'scaleX( 1 )'
                    ol.style.display   = 'block'
                    // close menu and continue
                    setTimeout( () => {
                        A.toggleMenu( done )
                    }, 0 )
                }
                // else if next view is project, find preview to scale
                if ( !window._popState.detected && this.nextView.type === 'project' ) {
                    let prjs = W.sel( `.project-preview a[href='${ this.$route.path }'] video` )
                    for ( let el of prjs ) {
                        let rect = el.getBoundingClientRect()
                        if ( rect.top < window.innerHeight && rect.top + rect.height >= 0 ) {
                            let mix = el.parentElement
                            let prj = mix.parentElement.parentElement.parentElement
                            prj.classList.add( 'zoom' )
                            // trigger video
                            if ( T.hasMouse() && mix.dataset.ready === 'true' && el.paused ) {
                                el.currentTime = 0
                                el.play()
                                    .then( () => {
                                        el.style.opacity = 1
                                    } )
                                    .catch( () => {

                                    } )
                            }
                            // prepare zoom
                            let rect  = mix.getBoundingClientRect()
                            let from  = rect.width / mix.offsetWidth
                            let to    = 0.1 + Math.max( window.innerWidth / mix.offsetWidth, window.innerHeight / mix.offsetHeight )
                            let midx  = rect.left + rect.width * 0.5
                            let midy  = rect.top + rect.height * 0.5
                            let offx  = window.innerWidth * 0.5 - midx
                            let offy  = window.innerHeight * 0.5 - midy
                            // animate
                            setTimeout( () => {
                                A.zoom( mix, offx, offy, from, to, done )
                            }, 0 )
                            return
                        }
                    }
                }
                // else trigger default animation
                setTimeout( () => {
                    A.leave( () => {
                        // finally continue
                        done()
                    } )
                }, 0 )
            },

            routerEnter( el, done ) {
                this.nextView = this.nextView || this.getTypeTitle()

                // a new route is beeing entered
                // console.log( `entering <${ el.dataset.type }> from <${ this.lastView || 'nowhere' }>` )

                // reset scroll position
                W.scroll.zero()

                setTimeout( () => {
                    // hide overlay
                    W.sel( '#overlay' )[0].style.display = 'none'

                    // enable scrolling and links
                    W.body.classList.remove( 'fixed' )
                    W.body.classList.remove( 'block' )

                    // update page title
                    S.setTitle( this.nextView.title )

                    // preload images
                    T.preloadImages( el )

                    // attach eventlisteners
                    if ( T.hasMouse() ) {
                        W.sel( '.project-preview a' ).forEach( el => {
                            el.addEventListener( 'mouseenter', this.hoverBegin )
                            el.addEventListener( 'mouseleave', this.hoverEnd )
                        } )
                    }

                    // trigger animation
                    setTimeout( () => {
                        A.enter( () => {
                            // unblock and reset back button
                            window._popState.detected = false
                            window._popState.block = false
                            // finally continue
                            done()
                        } )
                    }, 0 )
                }, 0 )
            },

            hoverBegin( el ) {
                let mix = el.target.querySelector( '.media-mix' )
                let vid = el.target.querySelector( 'video' )
                mix.style.transform = 'scale( 1.025 )'
                if ( mix.dataset.ready === 'true' && vid.paused ) {
                    vid.currentTime = 0
                    vid.play()
                        .then( () => {
                            vid.style.opacity = 1
                        } )
                        .catch( () => {

                        } )
                }
            },

            hoverEnd( el ) {
                let mix = el.target.querySelector( '.media-mix' )
                let vid = el.target.querySelector( 'video' )
                vid.pause()
                vid.style.opacity = 0
                mix.style.transform = 'none'
            }
        }
    }
</script>

<style lang="scss">
    @import '~destyle.css/destyle.css';
    @import '@/styles/global.scss';
    @import '@/styles/issue.scss';
    @import '@/styles/animate.scss';
    @import '@/styles/navigation.scss';
    @import '@/styles/menu.scss';
    @import '@/styles/preview.scss';
    @import '@/styles/media.scss';
    @import '@/styles/content.scss';
</style>
