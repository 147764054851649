import { createApp } from 'vue/dist/vue.esm-bundler'
// import { createApp } from 'vue'
import '@/registerServiceWorker'
import A from './App.vue'
import R from '@/scripts/router'
import S from '@/scripts/site'
import T from '@/scripts/tools'

if ( !document.oldBrowser ) {
    // block back button during transitions
    window._popState = {
        block: false,
        detected: false
    }

    // back button detected
    window.addEventListener( 'popstate', () => {
        window._popState.detected = true
    } )

    // fetch content from database
    S.init( () => {
        // handle back button during transitions
        R.beforeEach( ( to, from, next ) => {
            // don't proceed if currently transitioning
            if ( window._popState.block ) {
                next( false )
                return
            }

            // otherwise continue
            next()
        } )

        // create app and mount it once router is ready
        const app = createApp( A )
        app.use( R )
        R.isReady().then( () => {
            T.getWebpSupport( () => {
                app.mount( '#app' )
            } )
        } )
    } )
}
