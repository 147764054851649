export default {

    webp: null,

    // half = 616
    // full = 1304

    // verify if webp is supported
    getWebpSupport( done ) {
        const img = new Image();
        img.onload = () => {
            this.webp = ( img.width > 0 ) && ( img.height > 0 )
            done()
        }
        img.onerror = () => {
            this.webp = false
            done()
        }
        img.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA'
    },

    // calc aspect ratio from url string
    // getAspectRatio( url, zero = false ) {
    //     let ar = zero ? 0 : 56.25
    //     let idx = url.lastIndexOf( '-' )
    //     if ( idx > 0 ) {
    //         url = url.substring( idx + 1 )
    //         idx = url.indexOf( '.' )
    //         if ( idx > 0 ) {
    //             url = url.substring( 0, idx )
    //             let size = url.split( 'x' )
    //             if ( size.length === 2 ) {
    //                 let w = parseInt( size[0] )
    //                 let h = parseInt( size[1] )
    //                 ar = 100 * h / w
    //                 // console.log( `url=${ url } w=${ w } h=${ h } ar=${ ar }` )
    //             }
    //         }
    //     }
    //     return ar
    // },

    // preload and fade in images
    preloadImages( doc ) {
        let imgs = doc.querySelectorAll( 'img' )
        imgs.forEach( el => {
            el.onload = () => {
                // console.log( `loaded ${ el.dataset.src }` )
                el.parentElement.dataset.ready = 'true'
                el.style.opacity = 1
            }
            el.src = `${ el.dataset.src }${ this.webp ? '.webp' : '' }`
        } )
    },

    hasMouse() {
        return window.matchMedia( '( pointer: fine ) and ( hover: hover )' ).matches
    }
}