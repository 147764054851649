<template>
    <div
        class="content animate"
        v-html="obj.content">
    </div>
</template>

<script>
    export default {
        name: 'content',

        props: {
            obj: Object
        }
    }
</script>
